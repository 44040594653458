import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  Title,
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img from "../../static/partner-marketing-strategy-template.webp"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"
import { ImageHome } from "../../components/image/Image.js"


function PartnerStrategyDocument({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Partner Marketing Strategy - Template" 
       description="This document includes an example of partner program setup, along with guidelines for building a partner marketing strategy."
       image={img}
       featuredImage={img} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/partner-marketing-strategy-template/">Partner Marketing Strategy - Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Partner Marketing Strategy - Template"/>
            <SubHeading subheading="This document includes an example of partner program setup, along with guidelines for building a partner marketing strategy." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/partner-marketing-strategy-template" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <ImageHome src={img} alt="homepage" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
          <TextMedium textMedium="We’ve used the frameworks and templates in this document to develop and implement partner programs and partner 
          marketing strategies for SaaS companies in various niches."/> 
          <TextMedium textMedium="While there is no one-size-fits-all setup when it comes to partnerships and the types of partners a SaaS company should acquire,
            there are some general principles you should consider when building your program. This document is meant to guide your thinking and help you 
            in creating a scalable system, but you might need to remove or add to it, so that it reflects your company's context and resources." />
          <GumroadForm 
            formName="Partner Marketing Strategy Template"
            redirectPage="https://socontent.gumroad.com/l/partner-marketing-strategy-template"
            buttonText="Get it on Gumroad"
            buttonLink="https://socontent.gumroad.com/l/partner-marketing-strategy-template"
            // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
          />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default PartnerStrategyDocument